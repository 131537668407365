import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Signal, computed, inject, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import {
	IonAccordion,
	IonAccordionGroup,
	IonBadge,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonList,
	IonMenu,
	IonMenuToggle,
	IonNote,
	IonToolbar,
	NavController,
} from '@ionic/angular/standalone';
import { TranslocoService } from '@ngneat/transloco';

import { ProjectsPermissions } from '@sulser-print/constants/permissions/projects-permissions';
import { ViewPermission, ViewPermissionDirective } from '@sulser-print/directives/view-permission.directive';
import { Project } from '@sulser-print/models/project/project';
import { Mapper } from '@sulser-print/models/shared/mapper';
import { IonIconPipe } from '@sulser-print/pipes/icon/ion-icon.pipe';
import { MapperPipe } from '@sulser-print/pipes/mapper.pipe';
import { PrintJobsStore } from '@sulser-print/services/print-jobs.store';
import { QuickFillingStore } from '@sulser-print/services/quick-filling.store';
import { TokenService } from '@sulser-print/services/token.service';
import { getDesignSystemIconUrl, getIonIconUrl, getSharedIconUrl } from '@sulser-print/utils/icon.utils';

import { AppRoute } from '../../../../../shared/constants/app.route.constants';

type MenuButton = {
	exact?: boolean;
	icon?: string;
	isOpen?: boolean;
	label: string;
	permission?: ViewPermission;
} & (
	| {
			badge: Signal<string | undefined>;
			badgeColor: string;
	  }
	| {
			badge?: never;
			badgeColor?: never;
	  }
) &
	(
		| {
				children?: Array<MenuButton>;
				routerLink?: never;
		  }
		| {
				children?: never;
				routerLink: string;
		  }
	);

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		IonMenu,
		IonButtons,
		IonToolbar,
		IonHeader,
		IonMenuToggle,
		IonButton,
		IonIcon,
		IonContent,
		IonNote,
		IonItem,
		RouterLinkActive,
		RouterLink,
		IonBadge,
		IonIconPipe,
		IonList,
		ViewPermissionDirective,
		NgTemplateOutlet,
		IonAccordionGroup,
		IonAccordion,
		MapperPipe,
	],
	selector: 'app-project-menu',
	standalone: true,
	styleUrl: './project-menu.component.scss',
	templateUrl: './project-menu.component.html',
})
export class ProjectMenuComponent {
	private readonly navController = inject(NavController);

	private readonly printJobsStore = inject(PrintJobsStore);

	private readonly quickFillingStore = inject(QuickFillingStore);

	private readonly tokenService = inject(TokenService);

	private readonly translocoService = inject(TranslocoService);

	activeProject = input<Project>();

	protected readonly menuButtonDetailIconMapper: Mapper<[MenuButton['isOpen'], boolean], string> = (isOpen, hasChildren) => {
		if (hasChildren) {
			return isOpen ? getIonIconUrl('chevron-up') : getIonIconUrl('chevron-down');
		}
		return getIonIconUrl('chevron-forward');
	};

	protected readonly menuButtons: Array<MenuButton>;

	@Input() menuId = 'project-menu';

	constructor() {
		this.menuButtons = [
			{
				icon: getDesignSystemIconUrl('folder-open'),
				label: this.translocoService.translate('project-data'),
				permission: ProjectsPermissions.READ_PROJECT_FOLDERS_PERMISSION,
				routerLink: AppRoute.DIRECTORIES,
			},
			{
				badge: computed(() => {
					const printJobsLength = this.printJobsStore.printJobs().length;
					return printJobsLength ? `·` : undefined;
				}),
				badgeColor: 'primary',
				children: [
					{
						badge: computed(() => {
							const printJobsLength = this.printJobsStore.printJobs().length;
							return printJobsLength ? `${printJobsLength}` : undefined;
						}),
						badgeColor: 'primary',
						exact: true,
						label: this.translocoService.translate('print-job'),
						routerLink: AppRoute.PRINT_JOBS,
					},
					{
						label: this.translocoService.translate('ordered-print-jobs'),
						routerLink: [AppRoute.PRINT_JOBS, AppRoute.ORDERS_LIST].join('/'),
					},
				],
				icon: getDesignSystemIconUrl('printer'),
				label: this.translocoService.translate('print-jobs'),
				permission: ProjectsPermissions.SHOW_PROJECT_PRINT_JOBS_PERMISSION,
			},
			{
				icon: getDesignSystemIconUrl('logs'),
				label: this.translocoService.translate('logs'),
				permission: ProjectsPermissions.SHOW_PROJECT_LOGS_PERMISSION,
				routerLink: AppRoute.LOGS,
			},
			{
				icon: getDesignSystemIconUrl('members'),
				label: this.translocoService.translate('project-users'),
				permission: ProjectsPermissions.SHOW_PROJECT_USERS_PERMISSION,
				routerLink: AppRoute.USERS,
			},
			{
				icon: getDesignSystemIconUrl('archive'),
				label: this.translocoService.translate('archive'),
				permission: ProjectsPermissions.SHOW_PROJECT_ARCHIVE_PERMISSION,
				routerLink: AppRoute.ARCHIVED,
			},
			{
				icon: getDesignSystemIconUrl('gear'),
				label: this.translocoService.translate('settings'),
				permission: ProjectsPermissions.UPDATE_PROJECT_PERMISSION,
				routerLink: AppRoute.EDIT,
			},
			{
				badge: computed(() => {
					const quickFillingsLength = this.quickFillingStore.getQuickFillingsCount();
					return quickFillingsLength ? `${quickFillingsLength}` : undefined;
				}),
				badgeColor: 'primary',
				icon: getSharedIconUrl('basket'),
				label: this.translocoService.translate('data-transfer'),
				routerLink: AppRoute.SEND_DATA,
			},
			{
				icon: getDesignSystemIconUrl('key'),
				label: this.translocoService.translate('project-api-keys'),
				permission: () => this.tokenService.isAdminUser(),
				routerLink: AppRoute.API_KEYS,
			},
		];
	}

	protected async onMenuButtonClicked(menuButton: MenuButton, menu: IonMenu) {
		if (menuButton.routerLink) {
			this.navController.setDirection('root');
			await menu.close();
		} else {
			menuButton.isOpen = !menuButton.isOpen;
		}
	}
}
