<ion-menu #menu class="main-menu" side="end" [contentId]="menuId" [menuId]="menuId">
	<ion-header class="main-menu-header">
		<ion-toolbar>
			<ion-buttons slot="end">
				<ion-menu-toggle [autoHide]="true">
					<ion-button color="primary" fill="clear" size="large">
						<ion-icon slot="icon-only" [src]="'close-outline' | ionIcon" />
					</ion-button>
				</ion-menu-toggle>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>

	<ion-content>
		<section class="ion-padding">
			@if (activeProject(); as activeProject) {
				<h2>{{ activeProject.name }}</h2>
				<ion-note> {{ activeProject.number }} </ion-note>
			}
		</section>
		<ion-accordion-group>
			<ion-list lines="full">
				@for (menuButton of menuButtons; track $index) {
					@if (menuButton.children?.length) {
						<ng-container *ngTemplateOutlet="itemWithChildren; context: { $implicit: menuButton }" />
					} @else {
						<ng-container *ngTemplateOutlet="nonChildItem; context: { $implicit: menuButton }" />
					}
				}
			</ion-list>
		</ion-accordion-group>
	</ion-content>
</ion-menu>

<ng-template #nonChildItem let-menuButton let-hasChildren="hasChildren">
	<ion-item
		*viewPermission="menuButton.permission ?? true"
		#rla="routerLinkActive"
		class="menu-button"
		button
		routerDirection="root"
		routerLinkActive="active"
		[detail]="true"
		[detailIcon]="menuButton.isOpen | mapper: menuButtonDetailIconMapper : hasChildren"
		[color]="rla.isActive ? 'light' : null"
		[routerLink]="menuButton.routerLink"
		[routerLinkActiveOptions]="{ exact: !!menuButton.exact }"
		(click)="onMenuButtonClicked(menuButton, menu)"
	>
		<ion-icon slot="start" class="icon" [src]="menuButton.icon" />
		{{ menuButton.label }}
		@if (menuButton.badge?.(); as badge) {
			@if (badge.length) {
				<ion-badge slot="end" [color]="menuButton.badgeColor"> {{ badge }} </ion-badge>
			}
		}
	</ion-item>
</ng-template>

<ng-template #itemWithChildren let-menuButton>
	<ion-accordion [value]="menuButton.label">
		<div slot="header">
			<ng-container *ngTemplateOutlet="nonChildItem; context: { $implicit: menuButton, hasChildren: true }" />
		</div>
		<ion-list slot="content" lines="full">
			@for (child of menuButton.children; track $index) {
				<ng-container *ngTemplateOutlet="nonChildItem; context: { $implicit: child }" />
			}
		</ion-list>
	</ion-accordion>
</ng-template>
